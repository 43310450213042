
export type UsersActionType = 
'GETTING_USER_PROFILE' |
'GETTING_USER_PROFILE_SUCCESS' |
'GETTING_USER_PROFILE_FAILURE' |

'LOGGING_IN' |
'LOGGING_IN_SUCCESS' |
'LOGGING_IN_FAILURE' |

'LOGGING_OUT' |
'LOGGING_OUT_SUCCESS' |
'LOGGING_OUT_FAILURE' |

'CREATING_USER' |
'CREATING_USER_SUCCESS' |
'CREATING_USER_FAILURE';

const GETTING_USER_PROFILE: UsersActionType = 'GETTING_USER_PROFILE';
const GETTING_USER_PROFILE_SUCCESS: UsersActionType = 'GETTING_USER_PROFILE_SUCCESS';
const GETTING_USER_PROFILE_FAILURE: UsersActionType = 'GETTING_USER_PROFILE_FAILURE';

const LOGGING_IN: UsersActionType = 'LOGGING_IN';
const LOGGING_IN_SUCCESS: UsersActionType = 'LOGGING_IN_SUCCESS';
const LOGGING_IN_FAILURE: UsersActionType = 'LOGGING_IN_FAILURE';

const LOGGING_OUT: UsersActionType = 'LOGGING_OUT';
const LOGGING_OUT_SUCCESS: UsersActionType = 'LOGGING_OUT_SUCCESS';
const LOGGING_OUT_FAILURE: UsersActionType = 'LOGGING_OUT_FAILURE';

const CREATING_USER: UsersActionType = 'CREATING_USER';
const CREATING_USER_SUCCESS: UsersActionType = 'CREATING_USER_SUCCESS';
const CREATING_USER_FAILURE: UsersActionType = 'CREATING_USER_FAILURE';

export {
  GETTING_USER_PROFILE,
  GETTING_USER_PROFILE_SUCCESS,
  GETTING_USER_PROFILE_FAILURE,

  LOGGING_IN,
  LOGGING_IN_SUCCESS,
  LOGGING_IN_FAILURE,

  LOGGING_OUT,
  LOGGING_OUT_SUCCESS,
  LOGGING_OUT_FAILURE,

  CREATING_USER,
  CREATING_USER_SUCCESS,
  CREATING_USER_FAILURE
}